export enum defaultThemeLightColors {
  primaryMainColor = '#F9FCFC',
  primaryLightColor = '#FFFFFF',
  primaryDarkColor = '#D6D6D6',
  primaryContrastText = '#46535E',
  secondaryMainColor = '#ACC0C4',
  secondaryLightColor = '#E5EFF0',
  secondaryDarkColor = '#007585',
  secondaryContrastText = '#FFFFFF',
  infoMainColor = '#007585',
  infoLightColor = '#72C0CB',
  infoDarkColor = '#007585',
  infoContrastText = '#FFFFFF',
  infoCustomColor1 = '',
  errorDarkColor = '#CB362D',
  errorMainColor = '#FD1717',
  errorLightColor = 'rgba(203, 54, 45, 0.1)',
  errorContrastTextColor = '#ffffff',
  hoverInfoButton = '#0BDFDF',
}

export enum defaultThemeDarkColors {
  primaryMainColor = '#6C757D',
  primaryLightColor = '#ACC0C4',
  primaryDarkColor = '#46535E',
  primaryContrastText = '#FFFFFF',
  secondaryMainColor = '#60929D',
  secondaryLightColor = '#E5EFF0',
  secondaryDarkColor = '#007585',
  secondaryContrastText = '#FFFFFF',
  infoMainColor = '#007585',
  infoLightColor = '#72C0CB',
  infoContrastText = '#FFFFFF',
  infoDarkColor = '#007585',
  errorDarkColor = '#CB362D',
  errorMainColor = '#FD1717',
  errorLightColor = 'rgba(203, 54, 45, 0.1)',
  errorContrastTextColor = '#ffffff',
  hoverInfoButton = '#0BDFDF',
}
