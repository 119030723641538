import React, { ReactElement, useEffect } from 'react';

import { LinearProgress } from '@mui/material';

import {
  usePathInterceptor,
  useAuthInterceptor,
  useAppVersionInterceptor,
} from 'src/shared/hooks';
import { useStore } from 'src/mst/StoreProvider';
import { getAuthenticationModel, getUserModel } from 'src/mst/selectors';
import { RoutedContent } from 'src/routing';

import { updateAbilityInstance, useAbilityCtx } from 'src/roleAccesses';
import { observer } from 'mobx-react-lite';

const App = (): ReactElement => {
  const ability = useAbilityCtx();
  const store = useStore();
  const authentication = getAuthenticationModel(store);
  const user = getUserModel(store);

  updateAbilityInstance(ability, user.roles);

  useAppVersionInterceptor();
  usePathInterceptor();
  useAuthInterceptor(authentication, user);

  useEffect(() => {
    authentication.checkAuth();
  }, []);

  useEffect(() => {
    if (authentication.isAuth) {
      user.fetchUserData();
    }
  }, [authentication.isAuth]);

  if (authentication.isAuth === null) {
    return <LinearProgress color='secondary' />;
  }

  return <RoutedContent />;
};

export default observer(App);
