export enum santanderThemeLightColors {
  primaryMainColor = '#F9FCFC',
  primaryLightColor = '#FFFFFF',
  primaryDarkColor = '#BEC6CC',
  primaryContrastText = '#6C757D',
  secondaryMainColor = '#4AA0AC',
  secondaryLightColor = '#ADDBE1',
  secondaryDarkColor = '#007585',
  secondaryContrastText = '#FFFFFF',
  infoMainColor = '#952859',
  infoLightColor = '#952859',
  infoDarkColor = '#952859',
  infoContrastText = '#FFFFFF',
  infoCustomColor1 = '',
  errorDarkColor = '#CB362D',
  errorMainColor = '#FD1717',
  errorLightColor = 'rgba(203, 54, 45, 0.1)',
  errorContrastTextColor = '#ffffff',
  hoverInfoButton = '#0BDFDF',
}

export enum santanderThemeDarkColors {
  primaryMainColor = '#6C757D',
  primaryLightColor = '#ACC0C4',
  primaryDarkColor = '#46535E',
  primaryContrastText = '#FFFFFF',
  secondaryMainColor = '#4AA0AC',
  secondaryLightColor = '#E5EFF0',
  secondaryDarkColor = '#007585',
  secondaryContrastText = '#FFFFFF',
  infoMainColor = '#952859',
  infoLightColor = '#952859',
  infoContrastText = '#FFFFFF',
  infoDarkColor = '#952859',
  errorDarkColor = '#CB362D',
  errorMainColor = '#FD1717',
  errorLightColor = 'rgba(203, 54, 45, 0.1)',
  errorContrastTextColor = '#ffffff',
  hoverInfoButton = '#0BDFDF',
}
