import React from 'react';
import { Theme } from '@nivo/core';

export interface NivoTheme {
  theme: Theme;
  themedColors: string[];
}

const NivoThemeContext = React.createContext<NivoTheme>(undefined);

export default NivoThemeContext;
