import { types } from 'mobx-state-tree';

import { SelectableItem } from 'src/shared/types';

export const SelectableMstType = types.custom({
  name: 'NavItem',
  fromSnapshot(value: SelectableItem) {
    return value;
  },
  toSnapshot(value: SelectableItem) {
    return value;
  },
  isTargetType(value: SelectableItem): boolean {
    return Boolean(value?.title && value?.value);
  },
  getValidationMessage(value: SelectableItem): string {
    return `${value} doesn't look like a valid structure`;
  },
});
