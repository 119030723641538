import { ComponentType, lazy, LazyExoticComponent, ReactElement } from 'react';

import { AbilitySubjects } from 'src/roleAccesses';
import { AppRoutes } from './appRoutes';
import { withAuthValidation, withRoleValidation } from './validators';

const BaseLayout = lazy(() => import('src/components/layouts/BaseLayout'));
const HomePage = lazy(() => import('src/pages/HomePage'));
const NoRoutePage = lazy(() => import('src/pages/NoRoutePage'));
const LoginPage = lazy(() => import('src/pages/LoginPage'));
const DashboardPage = lazy(() => import('src/pages/DashboardPage'));
const ContractsListPage = lazy(() => import('src/pages/ContractsListPage'));
const DamagesListPage = lazy(() => import('src/pages/DamagesListPage'));
const ContractDetailsPage = lazy(() => import('src/pages/ContractDetailsPage'));
const DamageDetailsPage = lazy(() => import('src/pages/DamageDetailsPage'));
const UserManagementPage = lazy(() => import('src/pages/UserManagementPage'));
const FaqPage = lazy(() => import('src/pages/FaqPage'));
const DamageReportPage = lazy(() => import('src/pages/DamageReportPage'));
const CustomerDetailsPage = lazy(() => import('src/pages/CustomerDetailsPage'));
const RegistrationPage = lazy(() => import('src/pages/RegistrationPage'));
const CreatePasswordPage = lazy(() => import('src/pages/CreatePasswordPage'));
const ChangePasswordPage = lazy(() => import('src/pages/ChangePasswordPage'));
const ForgotPasswordPage = lazy(() => import('src/pages/ForgotPasswordPage'));

export interface RouteItem {
  path: string;
  component: LazyExoticComponent<ComponentType>;
  validators?: ((component: () => ReactElement) => () => ReactElement)[];
  children?: RouteItem[];
}

const routingConfig = (isAuth: boolean, homePath: string): RouteItem[] => [
  {
    component: LoginPage,
    path: AppRoutes.login,
  },
  {
    component: ForgotPasswordPage,
    path: AppRoutes.forgotPassword,
  },
  {
    component: RegistrationPage,
    path: AppRoutes.Registration,
  },
  {
    component: CreatePasswordPage,
    path: AppRoutes.createPassword,
  },
  {
    component: ChangePasswordPage,
    path: AppRoutes.changePassword,
    validators: [withAuthValidation(isAuth)],
  },
  {
    component: BaseLayout,
    path: AppRoutes.root,
    children: [
      {
        component: HomePage,
        path: AppRoutes.root,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.home, homePath),
        ],
      },
      {
        component: DashboardPage,
        path: AppRoutes.dashboard,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.dashboardPage, homePath),
        ],
      },
      {
        component: ContractsListPage,
        path: AppRoutes.contracts,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.contractsListPage, homePath),
        ],
        children: [],
      },
      {
        component: ContractDetailsPage,
        path: AppRoutes.contract,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.contractDetailsPage, homePath),
        ],
      },
      {
        component: DamagesListPage,
        path: AppRoutes.damages,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.damagesListPage, homePath),
        ],
      },
      {
        component: DamageDetailsPage,
        path: AppRoutes.damage,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.damageDetailsPage, homePath),
        ],
      },
      {
        component: UserManagementPage,
        path: AppRoutes.userManagement,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.userManagementPage, homePath),
        ],
      },
      {
        component: FaqPage,
        path: AppRoutes.faq,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.faq, homePath),
        ],
      },
      {
        component: DamageReportPage,
        path: AppRoutes.damageReport,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.damageReportPage, homePath),
        ],
      },
      {
        component: CustomerDetailsPage,
        path: AppRoutes.customers,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.customersPage, homePath),
        ],
      },
      {
        component: CustomerDetailsPage,
        path: AppRoutes.customer,
        validators: [
          withAuthValidation(isAuth),
          withRoleValidation(AbilitySubjects.customersPage, homePath),
        ],
      },
    ],
  },
  {
    path: '*',
    component: NoRoutePage,
  },
];

export default routingConfig;
