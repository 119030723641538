import React, { useMemo } from 'react';
import useDarkMode from 'use-dark-mode';
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

import { NivoThemeProvider } from './themes/nivoTheme/nivo-theme-provider';

import { lightTheme, darkTheme } from './theme';

export const withTheme = (component: () => React.ReactNode) => () => {
  const darkMode = useDarkMode(true);

  const currentTheme = useMemo(
    () => (darkMode.value ? darkTheme : lightTheme),
    [darkMode.value]
  );

  return (
    <ThemeProvider theme={currentTheme}>
      <NivoThemeProvider theme={currentTheme}>
        <CssBaseline />
        {component()}
      </NivoThemeProvider>
    </ThemeProvider>
  );
};
