export enum damageReportTypes {
  partialTheft = 'partialTheft',
  totalTheft = 'totalTheft',
  gameDamage = 'gameDamage',
  elementalDamage = 'elementalDamage',
  glassDamage = 'glassDamage',
  rearEndCollision = 'rearEndCollision',
  headOnCollision = 'headOnCollision',
  collision = 'collision',
  parkingDamage = 'parkingDamage',
  skiddingAccident = 'skiddingAccident',
  deviationFromLane = 'deviationFromLane',
  shuntingDamage = 'shuntingDamage',
}
