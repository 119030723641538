import { useEffect, useCallback } from 'react';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import { useAxios } from 'src/lib/http-client/use-http-client';
import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { AuthResponse } from '../types';
import { AuthenticationModel, UserModel } from '../../mst/models';

const throttleFun = (
  fn: (refreshToken: string) => Promise<AuthResponse>,
  ms: number
): ((refreshToken: string) => Promise<AuthResponse>) => {
  let currentTime = 0;
  let result: Promise<AuthResponse>;
  return (refreshToken: string): Promise<AuthResponse> => {
    const now = Date.now();
    if (now - currentTime >= ms) {
      currentTime = now;
      result = fn(refreshToken);
      return result;
    }
    return result;
  };
};

export const useAuthInterceptor = (
  authentication: AuthenticationModel,
  user: UserModel
): void => {
  const axios = useAxios();
  const httpClient = useHttpClient();

  const makeRequestForUpdatingTokens = useCallback(
    async (refreshToken: string): Promise<AuthResponse> => {
      return await httpClient.post('/api/users/refresh-token', { refreshToken });
    },
    []
  );

  const updateTokensThrottle = throttleFun(makeRequestForUpdatingTokens, 10000);

  useEffect(() => {
    let reqInterceptor: number;
    let resInterceptor: number;
    if (authentication.isAuth) {
      const tokenInterceptor = (config: AxiosRequestConfig): AxiosRequestConfig => {
        const accessToken = localStorage.getItem('accessToken');
        if (accessToken && config.headers) {
          config.headers.Authorization = `Bearer ${accessToken}`;
        }
        return config;
      };
      const responseIntercepor = {
        response: (response: AxiosResponse) => {
          return response;
        },
        error: async (
          error: AxiosError & {
            config: {
              _isRetry: boolean;
            };
            response: {
              errors: { refreshToken: string[] };
            };
          }
        ) => {
          const originalRequest = error.config;
          if (error.response.status === 403) {
            localStorage.clear(); // If a refresh token exists, but error occurs
            authentication.setAuth(false); // during updating tokens
            user.clearUserData();
          }
          // If any request complete with 401 error and this request is not retrying update tokens request
          if (error.response.status === 401 && !error.config._isRetry) {
            error.config._isRetry = true;
            const refreshToken = localStorage.getItem('refreshToken');
            if (refreshToken) {
              // If a refresh token exists, try to update pair of tokens
              try {
                authentication.setTokensLoading(true);
                const response: AuthResponse = await updateTokensThrottle(refreshToken);
                localStorage.setItem('accessToken', response.accessToken);
                localStorage.setItem('refreshToken', response.refreshToken);
                return axios(originalRequest);
              } catch {
                localStorage.clear(); // If a refresh token exists, but error occurs
                authentication.setAuth(false); // during updating tokens
                user.clearUserData();
              } finally {
                authentication.setTokensLoading(false);
              }
            } else {
              localStorage.clear(); // If the error occurs, but a user doesn't have a refresh token
              authentication.setAuth(false); // Then unauthorize the user
              user.clearUserData();
            }
            return Promise.reject(error);
          } else {
            return Promise.reject(error);
          }
        },
      };
      reqInterceptor = axios.interceptors.request.use(tokenInterceptor);
      resInterceptor = axios.interceptors.response.use(
        responseIntercepor.response,
        responseIntercepor.error
      );
    }
    return () => {
      if (reqInterceptor && resInterceptor) {
        axios.interceptors.request.eject(reqInterceptor);
        axios.interceptors.response.eject(resInterceptor);
      }
    };
  }, [authentication.isAuth, makeRequestForUpdatingTokens]);
};
