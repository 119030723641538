import { damageAssignments } from 'src/shared/constants/damageAssignments';
import { damageReportTypes } from '../constants';

export const damageTypeAndAssignamentMap: { [key: string]: string[] } = {
  [damageReportTypes.partialTheft]: [damageAssignments.partiallyComprehensive],
  [damageReportTypes.totalTheft]: [damageAssignments.partiallyComprehensive],
  [damageReportTypes.gameDamage]: [damageAssignments.partiallyComprehensive],
  [damageReportTypes.elementalDamage]: [damageAssignments.partiallyComprehensive],
  [damageReportTypes.glassDamage]: [damageAssignments.partiallyComprehensive],
  [damageReportTypes.rearEndCollision]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
  [damageReportTypes.headOnCollision]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
  [damageReportTypes.collision]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
  [damageReportTypes.parkingDamage]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
  [damageReportTypes.skiddingAccident]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
  [damageReportTypes.deviationFromLane]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
  [damageReportTypes.shuntingDamage]: [
    damageAssignments.fullyComprehensive,
    damageAssignments.liability,
  ],
};
