import React, { ReactElement } from 'react';
import { Outlet } from 'react-router-dom';

interface RouteProps {
  path: string;
  validators?: ((component: () => ReactElement) => () => ReactElement)[];
}

const RouteProps = ({ validators }: RouteProps): ReactElement | null => {
  if (!Array.isArray(validators) || !validators.length) {
    return <Outlet />;
  }

  const OutletWithValidators = validators.reduce(
    (Result, Validator) => {
      return Validator(Result);
    },
    () => <Outlet />
  );

  return <OutletWithValidators />;
};
export default RouteProps;
