import { formatISO, format } from 'date-fns';
import { enUS, de } from 'date-fns/locale';
import { Languages } from 'src/shared/constants';

export interface IDateTimeService {
  getDateISOStringWithoutTimeOffset(value: string | number): string | null;
}

export class DateTimeService implements IDateTimeService {
  public getDateISOStringWithoutTimeOffset(value: string | number): string | null {
    if (!value) {
      return null;
    }
    const formattedDate = formatISO(new Date(value), { representation: 'date' });
    return formattedDate;
  }
  public getDateStringWithDayOfWeek(
    value: string | number,
    locale: string
  ): string | null {
    if (!value) {
      return null;
    }
    const options: { locale?: Locale } = {};
    if (locale === Languages.DE) {
      options.locale = de;
    }
    if (locale === Languages.EN) {
      options.locale = enUS;
    }
    const dayOfWeek = format(new Date(value), 'EE', options);
    const date = format(new Date(value), 'P', options);
    return `${dayOfWeek} ${date}`;
  }
  public getDateISOStringWithTime(value: string | number): string | null {
    if (!value) {
      return null;
    }
    const formattedTime = formatISO(new Date(value), { representation: 'time' });
    return formattedTime.split('+')[0];
  }
  public getFullDateISOString(value: string | null): string | null {
    if (!value) {
      return null;
    }
    const formattedTime = formatISO(new Date(value), { representation: 'complete' });
    return formattedTime.split('+')[0];
  }
}
