import { Instance, SnapshotOut, types } from 'mobx-state-tree';

import { Authentication } from './authentication';
import { User } from './user';
import { GlobalFilter } from './globalFilter';
import { Contracts } from './contracts';
import { ContractDetails } from './contractDetails';
import { UmUsers } from './umUsers';
import { DamageReport } from './damageReport';
import { Damages } from './damages';
import { DamageDetails } from './damageDetails';
import { Customer } from './customer';

export const Store = types.model('Root', {
  authentication: Authentication,
  user: User,
  globalFilter: GlobalFilter,
  contracts: Contracts,
  contractDetails: ContractDetails,
  damageDetails: DamageDetails,
  umUsers: UmUsers,
  damageReport: DamageReport,
  damages: Damages,
  customer: Customer,
});

export type StoreModel = Instance<typeof Store>;
export type IStoreSnapshotOut = SnapshotOut<typeof Store>;
