import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';

import { FileMstType } from 'src/mst/types';
import { DetailedDamageDto, FileDto } from 'src/shared/types';

export const DamageDetails = types
  .model('DamageDetailsModel', {
    id: types.maybeNull(types.string),
    risk: types.maybeNull(types.string),
    contractNumber: types.maybeNull(types.string),
    dayOfDamage: types.maybeNull(types.string),
    classification: types.maybeNull(types.string),
    status: types.maybeNull(types.string),
    totalDamage: types.maybeNull(types.number),
    excess: types.maybeNull(types.number),
    dayOfReport: types.maybeNull(types.string),
    cost: types.maybeNull(types.number),
    product: types.maybeNull(types.string),
    customer: types.maybeNull(types.string),
    files: types.maybeNull(types.array(FileMstType)),
  })
  .actions((self) => {
    let initialState: IDamageDetailsSnapshotOut;
    const {
      env: { httpClient },
    } = getEnv(self);
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetDamagess(): void {
        applySnapshot(self, initialState);
      },
      fetchDamageDocuments: flow(function* ({ queryKey }: { queryKey: string[] }) {
        const [, id] = queryKey;
        if (!id) {
          return;
        }
        const data: FileDto[] = yield httpClient.get(`damages/${id}/documents`);

        applySnapshot(self, {
          ...self,
          files:
            data?.map((item) => ({
              id: item.amsIdNr,
              fileName: `${item.title}.${item.type}`,
            })) || [],
        });
      }),
      fetchDamageDetails: flow(function* ({ queryKey }: { queryKey: string[] }) {
        const [, id] = queryKey;
        if (!id) {
          return;
        }
        const data: DetailedDamageDto = yield httpClient.get(`damages/${id}`);
        applySnapshot(self, {
          ...self,
          contractNumber: data.contractNumber,
          classification: data.classification,
          product: data.product,
          risk: data.risk,
          status: data.status,
          dayOfDamage: data.dayOfDamage,
          dayOfReport: data.notification,
          totalDamage: data.totalPayment,
          excess: data.deductible,
          cost: data.payment,
          customer: data.customer,
        });
      }),
    };
  });

export type DamageDetailsModel = Instance<typeof DamageDetails>;
export type IDamageDetailsSnapshotOut = SnapshotOut<typeof DamageDetails>;
export type IDamageDetailsSnapshotIn = SnapshotIn<typeof DamageDetails>;
