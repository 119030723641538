import { object, boolean, string, date, InferType } from 'yup';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const policeAndWitnessSchema = object({
  [damageReportFieldNames.recorderByThePolice]: boolean().required().nullable(),
  [damageReportFieldNames.receivingPoliceStation]: string().when(
    damageReportFieldNames.recorderByThePolice,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
  [damageReportFieldNames.policeFileNumber]: string().when(
    damageReportFieldNames.recorderByThePolice,
    {
      is: (val: boolean) => {
        return val;
      },
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
  [damageReportFieldNames.alcoholOrDrugUse]: boolean().required().nullable(),
  [damageReportFieldNames.warnedByThePolice]: boolean().required().nullable(),
  [damageReportFieldNames.whoWasWarnedByThePolice]: string().when(
    damageReportFieldNames.warnedByThePolice,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
  [damageReportFieldNames.isDriverLicenseAvailable]: boolean().required().nullable(),
  [damageReportFieldNames.driverLicenseClass]: string().when(
    damageReportFieldNames.isDriverLicenseAvailable,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
  [damageReportFieldNames.dateOfIssue]: date().when(
    damageReportFieldNames.isDriverLicenseAvailable,
    {
      is: (val: boolean) => val,
      then: date().required().nullable(),
      otherwise: date().nullable(),
    }
  ),
  [damageReportFieldNames.isWitnesses]: boolean().required().nullable(),
  [damageReportFieldNames.withnessEmail]: string().email().nullable(),
  [damageReportFieldNames.witnessName]: string().when(
    damageReportFieldNames.isWitnesses,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
});

export type PoliceAndWitnessSchemaType = InferType<typeof policeAndWitnessSchema>;
