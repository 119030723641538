export enum AbilitySubjects {
  app = 'app',
  home = 'home',
  dashboardPage = 'dashboardPage',
  contractsListPage = 'contractsListPage',
  damagesListPage = 'damagesListPage',
  contractDetailsPage = 'contractDetailsPage',
  damageDetailsPage = 'damageDetailsPage',
  userManagementPage = 'userManagementPage',
  umUsersSection = 'umUsersSection',
  umNotificationsSection = 'umNotificationsSection',
  umNewsSection = 'umNewsSection',
  damageReportPage = 'damageReportPage',
  customersPage = 'customersPage',
  customerDetailsPage = 'customerDetailsPage',
  internalRoleGroup = 'internalRoleGroup',
  faq = 'faq',
}
