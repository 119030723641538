import { SelectableItem } from 'src/shared/types';

import { damageReportTypes } from '../constants';

export const damageTypeOptions: SelectableItem[] = [
  { title: 'damageTypes:partialTheft', value: damageReportTypes.partialTheft },
  { title: 'damageTypes:totalTheft', value: damageReportTypes.totalTheft },
  { title: 'damageTypes:gameDamage', value: damageReportTypes.gameDamage },
  { title: 'damageTypes:elementalDamage', value: damageReportTypes.elementalDamage },
  { title: 'damageTypes:glassDamage', value: damageReportTypes.glassDamage },
  { title: 'damageTypes:rearEndCollision', value: damageReportTypes.rearEndCollision },
  { title: 'damageTypes:headOnCollision', value: damageReportTypes.headOnCollision },
  { title: 'damageTypes:collision', value: damageReportTypes.collision },
  { title: 'damageTypes:parkingDamage', value: damageReportTypes.parkingDamage },
  { title: 'damageTypes:skiddingAccident', value: damageReportTypes.skiddingAccident },
  { title: 'damageTypes:deviationFromLane', value: damageReportTypes.deviationFromLane },
  { title: 'damageTypes:shuntingDamage', value: damageReportTypes.shuntingDamage },
];
