import { flow } from 'mobx-state-tree';
import { generatePath } from 'react-router-dom';
import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  getEnv,
  getRoot,
} from 'mobx-state-tree';
import { StoreModel } from 'src/mst/models/root';
import { SelectableItem, UserSettingsDto } from 'src/shared/types';
import { SelectableMstType } from 'src/mst/types';
import { AppRoutes } from 'src/routing';
import {
  internalRole,
  customerRoles,
  userManagementSections,
} from 'src/shared/constants';
import { getUserSettingsAdapter } from './adapters';

export const User = types
  .model('UserModel', {
    id: types.maybeNull(types.string),
    roles: types.maybeNull(types.array(types.string)),
    name: types.maybeNull(types.string),
    surname: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    divisions: types.maybeNull(types.array(SelectableMstType)),
  })
  .actions((self) => {
    const { globalFilter }: StoreModel = getRoot(self);
    let initialState: IUserSnapshotOut;
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetUserData(): void {
        applySnapshot(self, initialState);
      },
      setUserData(data: UserSettingsDto): void {
        applySnapshot(self, {
          ...self,
          id: data.userInfo.id,
          roles: data.userInfo.roles,
          name: data.userInfo.name,
          email: data.userInfo.email,
          surname: data.userInfo.surname,
          divisions: data.divisions.map((item) => ({
            title: item.label,
            value: item.value,
          })),
        });

        globalFilter.setCustomers(
          data.customers.map((customer) => {
            return {
              title: customer.customer,
              value: customer.id,
              additionalTitles: [customer.customerName2, customer.customerName3].filter(
                (item) => Boolean(item)
              ),
            };
          })
        );
      },
      clearUserData(): void {
        applySnapshot(self, {});
      },
    };
  })
  .actions((self) => {
    const {
      env: { httpClient },
    } = getEnv(self);
    return {
      fetchUserData: flow(function* () {
        const data: UserSettingsDto = yield httpClient.get(`users/settings`);
        const formattedData = getUserSettingsAdapter(data);
        if (formattedData) {
          self.setUserData(data);
        }
      }),
      updateUserData: flow(function* (values: UserSettingsDto) {
        yield httpClient.put('users/retail-customer', values);
      }),
    };
  })
  .views((self) => {
    return {
      get userData(): IUserSnapshotOut {
        return { ...self };
      },
      get userDivisions(): SelectableItem[] {
        return Array.isArray(self.divisions) && self.divisions.length
          ? self.divisions.map((item) => ({ title: item.value, value: item.value }))
          : [];
      },
      get homePath(): string {
        const { roles } = self;
        if (!Array.isArray(roles) || roles.length === 0) {
          return AppRoutes.login;
        }
        if (roles.some((role: string) => role === customerRoles.customer)) {
          return `${AppRoutes.root}/`;
        }
        if (roles.some((role: string) => role === internalRole.admin)) {
          return generatePath(AppRoutes.userManagement, {
            tab: userManagementSections.users,
          });
        }
        if (roles.some((role: string) => role === internalRole.newsManager)) {
          return generatePath(AppRoutes.userManagement, {
            tab: userManagementSections.news,
          });
        }
        return '';
      },
    };
  });

export type UserModel = Instance<typeof User>;
export type IUserSnapshotOut = SnapshotOut<typeof User>;
export type IUserSnapshotIn = SnapshotIn<typeof User>;
