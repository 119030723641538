import { Components } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import {
  santanderThemeDarkColors,
  santanderThemeLightColors,
} from './santanderThemeColors';

export const santanderLightThemeComponents: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        fontSize: '10px',
      },
      body: {
        height: '100%',
        margin: '0px auto',
      },
      a: {
        color: santanderThemeLightColors.secondaryDarkColor,
        textDecoration: 'none',
        fontWeight: 400,
        fontSize: '1.6rem',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
      'div,ul,tbody': {
        scrollbarWidth: 'thin',
        scrollbarColor: `#888888 #595959`,
        '&::-webkit-scrollbar': {
          width: 12,
          height: 6,
        },
        '&::-webkit-scrollbar-track': {
          background: santanderThemeLightColors.primaryDarkColor,
        },
        '&::-webkit-scrollbar-thumb': {
          background: santanderThemeLightColors.secondaryMainColor,
        },
      },
      '& .MuiCalendarPicker-root': {
        '& .MuiPickersDay-root': {
          fontSize: '1.6rem',
        },
        '& .MuiTypography-root': {
          fontSize: '1.6rem',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        fontSize: '1.6rem',
        fontWeight: 600,
        textTransform: 'none',
      },
      containedSecondary: {
        backgroundColor: santanderThemeLightColors.secondaryDarkColor,
        '&:hover': {
          backgroundColor: santanderThemeLightColors.secondaryMainColor,
        },
      },
      outlinedSecondary: {
        borderColor: santanderThemeLightColors.secondaryDarkColor,
        color: santanderThemeLightColors.secondaryDarkColor,
        '&:hover': {
          borderColor: santanderThemeLightColors.secondaryMainColor,
          color: santanderThemeLightColors.secondaryMainColor,
        },
      },
      outlinedPrimary: {
        borderColor: santanderThemeLightColors.primaryContrastText,
        color: santanderThemeLightColors.primaryContrastText,
        '&:hover': {
          borderColor: santanderThemeLightColors.secondaryMainColor,
          color: santanderThemeLightColors.secondaryMainColor,
        },
      },
      outlined: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: 400,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: santanderThemeLightColors.errorMainColor,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
        '&:not(.Mui-checked)': {
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${santanderThemeLightColors.secondaryDarkColor}!important`,
          color: santanderThemeLightColors.secondaryContrastText,
        },
      },
      today: {
        backgroundColor: `${santanderThemeLightColors.secondaryLightColor}!important`,
        color: santanderThemeLightColors.primaryContrastText,
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        backgroundColor: santanderThemeLightColors.primaryMainColor,
      },
    },
  },
  MuiClockPicker: {
    styleOverrides: {
      root: {
        backgroundColor: santanderThemeLightColors.primaryMainColor,
        '&>div:nth-of-type(2)>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: santanderThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: santanderThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: santanderThemeLightColors.secondaryDarkColor,
            color: santanderThemeLightColors.secondaryContrastText,
          },
        },
        '&>div>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: santanderThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: santanderThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: santanderThemeLightColors.secondaryDarkColor,
            color: santanderThemeLightColors.secondaryContrastText,
          },
        },
      },
    },
  },
};

export const santanderDarkThemeComponents: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        fontSize: '10px',
      },
      body: {
        height: '100%',
        margin: '0px auto',
      },
      a: {
        color: santanderThemeDarkColors.secondaryContrastText,
        textDecoration: 'none',
        fontWeight: 400,
        fontSize: '1.6rem',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
      'div,ul,tbody': {
        scrollbarWidth: 'thin',
        scrollbarColor: `#888888 #595959`,
        '&::-webkit-scrollbar': {
          width: 12,
          height: 6,
        },
        '&::-webkit-scrollbar-track': {
          background: santanderThemeDarkColors.primaryDarkColor,
        },
        '&::-webkit-scrollbar-thumb': {
          background: santanderThemeDarkColors.secondaryMainColor,
        },
      },
      '& .MuiCalendarPicker-root': {
        '& .MuiPickersDay-root': {
          fontSize: '1.6rem',
        },
        '& .MuiTypography-root': {
          fontSize: '1.6rem',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        fontSize: '1.6rem',
        fontWeight: 600,
        textTransform: 'none',
      },
      outlinedPrimary: {
        fontWeight: 400,
        color: santanderThemeDarkColors.primaryContrastText,
        borderColor: santanderThemeDarkColors.primaryContrastText,
        transition: '0.2s',
        '&:hover': {
          opacity: 0.8,
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderColor: santanderThemeDarkColors.primaryContrastText,
        },
      },
      outlinedSecondary: {
        borderColor: santanderThemeDarkColors.secondaryMainColor,
        color: santanderThemeDarkColors.secondaryContrastText,
        '&:hover': {
          borderColor: santanderThemeDarkColors.secondaryDarkColor,
        },
      },
      outlined: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: 400,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: santanderThemeDarkColors.errorMainColor,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
        '&:not(.Mui-checked)': {
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        backgroundColor: santanderThemeDarkColors.primaryDarkColor,
        '&.Mui-selected': {
          backgroundColor: `${santanderThemeDarkColors.secondaryDarkColor}!important`,
          color: santanderThemeDarkColors.secondaryContrastText,
        },
      },
      today: {
        backgroundColor: `${santanderThemeDarkColors.secondaryMainColor}!important`,
        color: santanderThemeDarkColors.primaryContrastText,
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        backgroundColor: santanderThemeDarkColors.primaryMainColor,
      },
    },
  },
  MuiClockPicker: {
    styleOverrides: {
      root: {
        backgroundColor: santanderThemeDarkColors.primaryMainColor,
        '&>div:nth-of-type(2)>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: santanderThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: santanderThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: santanderThemeDarkColors.secondaryDarkColor,
            color: santanderThemeDarkColors.secondaryContrastText,
          },
        },
        '&>div>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: santanderThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: santanderThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: santanderThemeDarkColors.secondaryDarkColor,
            color: santanderThemeDarkColors.secondaryContrastText,
          },
        },
      },
    },
  },
};
