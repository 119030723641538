export enum appInstances {
  general = 'B2C-General',
  uber = 'B2C-Uber',
  uberAT = 'B2C-UberAT',
  santander = 'B2C-Santander',
  sgiffoxxk = 'B2C-Sgiffoxxk',
  aon = 'B2C-Aon',
  hector = 'B2C-Hector',
  ceat = 'B2C-Ceat',
  autoHero = 'B2C-AutoHero',
}
