import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';

import { damageAssignments } from 'src/shared/constants/damageAssignments';
import {
  damageReportProcessSteps,
  damageReportFieldNames,
  damageReportCauses,
} from 'src/pages/DamageReportPage/constants';
import { damageTypeAndAssignamentMap } from 'src/pages/DamageReportPage/configs';
import { buildFormData } from 'src/shared/utils';
import { DamageReportDto } from 'src/shared/types';
import { maxNumberOfAccidentOpponents } from 'src/pages/DamageReportPage/constants';

import {
  DamageReportThirdParty,
  IDamageReportThirdPartySnapshotIn,
} from './damageReportThirdParty';
import {
  DamageReportAttachment,
  IDamageReportAttachmentSnapshotOut,
  IDamageReportAttachmentSnapshotIn,
} from './damageReportAttachment';
import {
  accidentBreakdownSchema,
  immediateHelpSchema,
  ownVehicleDamagedSchema,
  otherPartyInvolvedSchema,
  moreAccidentOpponentsSchema,
  damageInfoValidationSchema,
  accidentLocationSchema,
  policeAndWitnessSchema,
} from './validationSchemas';
import { damageReportRequestAdapter } from './helpers';

export const DamageReport = types
  .model('DamageReportModel', {
    isValid: types.maybeNull(types.boolean),
    currentStep: types.maybeNull(types.string),
    previousSteps: types.array(types.string),
    [damageReportFieldNames.isImmediateHelpRequired]: types.maybeNull(types.boolean),
    [damageReportFieldNames.reason]: types.maybeNull(types.string),
    [damageReportFieldNames.isAccident]: types.maybeNull(types.boolean),
    [damageReportFieldNames.isOwnVehicleDamaged]: types.maybeNull(types.boolean),
    [damageReportFieldNames.damageType]: types.maybeNull(types.string),
    [damageReportFieldNames.assignments]: types.maybeNull(types.array(types.string)),
    [damageReportFieldNames.isOtherPartyInvolved]: types.maybeNull(types.boolean),
    [damageReportFieldNames.responsibility]: types.maybeNull(types.string),
    thirdParties: types.maybeNull(types.array(DamageReportThirdParty)),
    [damageReportFieldNames.addMoreAccidentOpponents]: types.maybeNull(types.boolean),
    [damageReportFieldNames.damageDay]: types.maybeNull(types.string),
    [damageReportFieldNames.damageTime]: types.maybeNull(types.string),
    [damageReportFieldNames.licenseNumber]: types.maybeNull(types.string),
    [damageReportFieldNames.damageCause]: types.maybeNull(types.string),
    [damageReportFieldNames.driverFirstName]: types.maybeNull(types.string),
    [damageReportFieldNames.driverLastName]: types.maybeNull(types.string),
    [damageReportFieldNames.driverStreet]: types.maybeNull(types.string),
    [damageReportFieldNames.driverPostcode]: types.maybeNull(types.string),
    [damageReportFieldNames.driverCity]: types.maybeNull(types.string),
    [damageReportFieldNames.driverPhoneNumber]: types.maybeNull(types.string),
    [damageReportFieldNames.detailedDamageDescription]: types.maybeNull(types.string),
    [damageReportFieldNames.breakdownStreet]: types.maybeNull(types.string),
    [damageReportFieldNames.breakdownPostcode]: types.maybeNull(types.string),
    [damageReportFieldNames.breakdownCity]: types.maybeNull(types.string),
    [damageReportFieldNames.breakdownCountry]: types.maybeNull(types.string),
    [damageReportFieldNames.recorderByThePolice]: types.maybeNull(types.boolean),
    [damageReportFieldNames.receivingPoliceStation]: types.maybeNull(types.string),
    [damageReportFieldNames.policeFileNumber]: types.maybeNull(types.string),
    [damageReportFieldNames.alcoholOrDrugUse]: types.maybeNull(types.boolean),
    [damageReportFieldNames.warnedByThePolice]: types.maybeNull(types.boolean),
    [damageReportFieldNames.whoWasWarnedByThePolice]: types.maybeNull(types.string),
    [damageReportFieldNames.isDriverLicenseAvailable]: types.maybeNull(types.boolean),
    [damageReportFieldNames.driverLicenseClass]: types.maybeNull(types.string),
    [damageReportFieldNames.dateOfIssue]: types.maybeNull(types.string),
    [damageReportFieldNames.isWitnesses]: types.maybeNull(types.boolean),
    [damageReportFieldNames.witnessName]: types.maybeNull(types.string),
    [damageReportFieldNames.witnessStreet]: types.maybeNull(types.string),
    [damageReportFieldNames.witnessPostcode]: types.maybeNull(types.string),
    [damageReportFieldNames.witnessCity]: types.maybeNull(types.string),
    [damageReportFieldNames.witnessPhoneNumber]: types.maybeNull(types.string),
    [damageReportFieldNames.withnessEmail]: types.maybeNull(types.string),
    [damageReportFieldNames.attachments]: types.maybeNull(
      types.array(DamageReportAttachment)
    ),
  })
  .actions((self) => {
    return {
      triggerImmediateHelpValidation() {
        self.isValid = immediateHelpSchema.isValidSync({
          [damageReportFieldNames.isImmediateHelpRequired]:
            self[damageReportFieldNames.isImmediateHelpRequired],
          [damageReportFieldNames.reason]: self[damageReportFieldNames.reason],
        });
      },
      triggerAccidentBreakdownValidation() {
        self.isValid = accidentBreakdownSchema.isValidSync({
          [damageReportFieldNames.isAccident]: self[damageReportFieldNames.isAccident],
        });
      },
      triggerOwnVehicleDamagedValidation() {
        self.isValid = ownVehicleDamagedSchema.isValidSync({
          [damageReportFieldNames.isOwnVehicleDamaged]:
            self[damageReportFieldNames.isOwnVehicleDamaged],
          [damageReportFieldNames.damageType]: self[damageReportFieldNames.damageType],
        });
      },
      triggerOtherPartyInvolvedSchema() {
        self.isValid = otherPartyInvolvedSchema.isValidSync({
          [damageReportFieldNames.isOtherPartyInvolved]:
            self[damageReportFieldNames.isOtherPartyInvolved],
          [damageReportFieldNames.responsibility]:
            self[damageReportFieldNames.responsibility],
        });
      },
      triggerMoreAccidentOpponentsValidation() {
        self.isValid = moreAccidentOpponentsSchema.isValidSync({
          [damageReportFieldNames.addMoreAccidentOpponents]:
            self[damageReportFieldNames.addMoreAccidentOpponents],
        });
      },
      triggerDamageInfoValidationSchema() {
        self.isValid = damageInfoValidationSchema.isValidSync({
          [damageReportFieldNames.damageDay]: self[damageReportFieldNames.damageDay],
          [damageReportFieldNames.damageCause]: self[damageReportFieldNames.damageCause],
          [damageReportFieldNames.licenseNumber]:
            self[damageReportFieldNames.licenseNumber],
        });
      },
      triggerAccidentLocationSchema() {
        self.isValid = accidentLocationSchema.isValidSync({
          [damageReportFieldNames.detailedDamageDescription]:
            self[damageReportFieldNames.detailedDamageDescription],
        });
      },
      triggerPoliceAndWitnessValidation() {
        self.isValid = policeAndWitnessSchema.isValidSync({
          [damageReportFieldNames.recorderByThePolice]:
            self[damageReportFieldNames.recorderByThePolice],
          [damageReportFieldNames.receivingPoliceStation]:
            self[damageReportFieldNames.receivingPoliceStation],
          [damageReportFieldNames.policeFileNumber]:
            self[damageReportFieldNames.policeFileNumber],
          [damageReportFieldNames.alcoholOrDrugUse]:
            self[damageReportFieldNames.alcoholOrDrugUse],
          [damageReportFieldNames.warnedByThePolice]:
            self[damageReportFieldNames.warnedByThePolice],
          [damageReportFieldNames.whoWasWarnedByThePolice]:
            self[damageReportFieldNames.whoWasWarnedByThePolice],
          [damageReportFieldNames.isDriverLicenseAvailable]:
            self[damageReportFieldNames.isDriverLicenseAvailable],
          [damageReportFieldNames.driverLicenseClass]:
            self[damageReportFieldNames.driverLicenseClass],
          [damageReportFieldNames.dateOfIssue]: self[damageReportFieldNames.dateOfIssue],
          [damageReportFieldNames.isWitnesses]: self[damageReportFieldNames.isWitnesses],
          [damageReportFieldNames.withnessEmail]:
            self[damageReportFieldNames.withnessEmail],
          [damageReportFieldNames.witnessName]: self[damageReportFieldNames.witnessName],
        });
      },
      triggerValidation(): void {
        switch (self.currentStep) {
          case damageReportProcessSteps.isImmediateHelpRequired: {
            this.triggerImmediateHelpValidation();
            break;
          }
          case damageReportProcessSteps.breakdownAccident: {
            this.triggerAccidentBreakdownValidation();
            break;
          }
          case damageReportProcessSteps.isOwnVehicleDamaged: {
            this.triggerOwnVehicleDamagedValidation();
            break;
          }
          case damageReportProcessSteps.isOtherPartyInvolved: {
            this.triggerOtherPartyInvolvedSchema();
            break;
          }
          case damageReportProcessSteps.addMoreAccidentOpponents: {
            this.triggerMoreAccidentOpponentsValidation();
            break;
          }
          case damageReportProcessSteps.damageInfo: {
            this.triggerDamageInfoValidationSchema();
            break;
          }
          case damageReportProcessSteps.breakdownInfo: {
            this.triggerAccidentLocationSchema();
            break;
          }
          case damageReportProcessSteps.policeAndAttachments: {
            this.triggerPoliceAndWitnessValidation();
            break;
          }
          default: {
            break;
          }
        }
      },
    };
  })
  .actions((self) => {
    let initialState: IDamageReportSnapshotOut;
    const {
      env: { httpClient, dateTimeService },
    } = getEnv(self);
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetDamageReport(): void {
        applySnapshot(self, initialState);
      },
      setCurrentStep(value: string): void {
        self.currentStep = value;
        self.triggerValidation();
      },
      resetAddMoreAccidentOpponents(): void {
        self.addMoreAccidentOpponents = null;
      },
      addPreviousStep(value: string): void {
        if (value === damageReportProcessSteps.addMoreAccidentOpponents) {
          this.resetAddMoreAccidentOpponents();
        }
        applySnapshot(self, { ...self, previousSteps: [...self.previousSteps, value] });
      },
      removePreviousStep(): string {
        const previousSteps = self.previousSteps.pop();
        applySnapshot(self, {
          ...self,
          previousSteps: self.previousSteps.filter((item) => item !== previousSteps),
        });
        return previousSteps;
      },
      setFieldValue(name: string, value: unknown) {
        applySnapshot(self, { ...self, [name]: value });
        self.triggerValidation();
      },
      setBreakdownAccident(name: string, value: boolean) {
        if (!value) {
          applySnapshot(self, {
            ...self,
            [name]: value,
            [damageReportFieldNames.assignments]: [damageAssignments.letterOfProtection],
            [damageReportFieldNames.damageCause]: damageReportCauses.breakdown,
          });
        } else {
          applySnapshot(self, {
            ...self,
            [name]: value,
            [damageReportFieldNames.assignments]: [],
          });
        }
        self.triggerValidation();
      },
      setValid(value: boolean) {
        self.isValid = value;
      },
      setDamageType(value: string, externalAssignments?: string[]): void {
        let assignments: string[] | null = null;
        if (!externalAssignments) {
          if (value) {
            assignments = damageTypeAndAssignamentMap[value as damageReportFieldNames];
          } else {
            assignments = [];
          }
        } else {
          assignments = externalAssignments;
        }
        applySnapshot(self, {
          ...self,
          [damageReportFieldNames.damageType]: value,
          [damageReportFieldNames.assignments]: assignments,
        });
        self.triggerValidation();
      },
      addThirdParty(thirdParty: IDamageReportThirdPartySnapshotIn): void {
        const { thirdParties } = self;
        if (thirdParties.length < maxNumberOfAccidentOpponents) {
          applySnapshot(self, {
            ...self,
            thirdParties: [...self.thirdParties, thirdParty],
          });
        }
      },
      setOtherPartyInvolved(value: boolean): void {
        applySnapshot(self, {
          ...self,
          [damageReportFieldNames.isOtherPartyInvolved]: value,
          [damageReportFieldNames.assignments]: value
            ? [damageAssignments.liability, damageAssignments.fullyComprehensive]
            : [damageAssignments.fullyComprehensive],
        });
        self.triggerValidation();
      },
      setOwnVehicleDamaged(value: boolean): void {
        applySnapshot(self, {
          ...self,
          [damageReportFieldNames.isOwnVehicleDamaged]: value,
          [damageReportFieldNames.isOtherPartyInvolved]: value === false ? true : null,
        });
        self.triggerValidation();
      },
      addAttachments(newAttachments: IDamageReportAttachmentSnapshotIn[]): void {
        applySnapshot(self, {
          ...self,
          attachments: [...self.attachments, ...newAttachments],
        });
      },
      deleteAttachment(id: string): void {
        applySnapshot(self, {
          ...self,
          attachments: self.attachments.filter((item) => item.id !== id),
        });
      },
      reportDamage: flow(function* () {
        const formattedData: DamageReportDto = damageReportRequestAdapter(
          self,
          dateTimeService
        );
        const formData = new FormData();
        buildFormData(formData, formattedData, null);
        yield httpClient.post('damage-reports/send-to-ams', formData);
      }),
    };
  })
  .views((self) => {
    return {
      get stepDependentValue(): boolean | number {
        if (self.currentStep === damageReportProcessSteps.isImmediateHelpRequired) {
          return self.isImmediateHelpRequired;
        }
        if (self.currentStep === damageReportProcessSteps.breakdownAccident) {
          return self.isAccident;
        }
        if (self.currentStep === damageReportProcessSteps.isOwnVehicleDamaged) {
          return self.assignments?.includes(damageAssignments.partiallyComprehensive);
        }
        if (self.currentStep === damageReportProcessSteps.isOtherPartyInvolved) {
          return self.isOtherPartyInvolved;
        }
        if (self.currentStep === damageReportProcessSteps.addMoreAccidentOpponents) {
          return self.addMoreAccidentOpponents;
        }
        if (self.currentStep === damageReportProcessSteps.thirdPartyDetails) {
          return self.thirdParties.length + 1;
        }
        return null;
      },
      get isLastStep(): boolean {
        return (
          self.currentStep === damageReportProcessSteps.immediateHelpMessage ||
          self.currentStep === damageReportProcessSteps.policeAndAttachments
        );
      },
      get attachmentFiles(): IDamageReportAttachmentSnapshotOut[] {
        return Array.isArray(self.attachments)
          ? self.attachments.map((file) => ({ ...file }))
          : [];
      },
      get isImmediateHelpMessageStep(): boolean {
        return self.currentStep === damageReportProcessSteps.immediateHelpMessage;
      },
    };
  });

export type DamageReportModel = Instance<typeof DamageReport>;
export type IDamageReportSnapshotOut = SnapshotOut<typeof DamageReport>;
export type IDamageReportSnapshotIn = SnapshotIn<typeof DamageReport>;
