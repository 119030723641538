import { appInstances } from './shared/constants';

export const isDevelopment = process.env.NODE_ENV === 'development';
export const baseUrl = process.env.REACT_APP_PUBLIC_HTTP_BASE_URL;
export const fallbackLanguage = process.env.REACT_APP_FALLBACK_LANG;
export const applicationVersion = process.env.REACT_APP_VERSION;
export const appInstance = process.env.REACT_APP_INSTANCE;
export const uberApplicationLineUrl = process.env.REACT_APP_UBER_APPLICATION_LINE_URL;
export const isUberInstance = appInstances.uber === appInstance;
export const isSantanderInstance = appInstances.santander === appInstance;
export const isAutoHeroInstance = appInstances.autoHero === appInstance;
export const isUberATInstance = appInstances.uberAT === appInstance;
