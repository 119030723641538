import { object, date, string, InferType } from 'yup';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const damageInfoValidationSchema = object({
  [damageReportFieldNames.damageDay]: date().required().nullable(),
  [damageReportFieldNames.licenseNumber]: string().required().nullable(),
});

export type DamageInfoSchemaType = InferType<typeof damageInfoValidationSchema>;
