import { createTheme } from '@mui/material/styles';
import responsiveFontSizes from '@mui/material/styles/responsiveFontSizes';

import { appInstance } from 'src/environment';
import { themeModes } from './themeModes';

import {
  themeSpacingsFactory,
  themeBreakpointsFactory,
  themeTypographyFactory,
  themeComponentsFactory,
  themePaletteFactory,
} from './themeFactories';

export const lightTheme = createTheme({
  spacing: themeSpacingsFactory(appInstance),
  breakpoints: themeBreakpointsFactory(appInstance),
  typography: themeTypographyFactory(appInstance),
  components: themeComponentsFactory(appInstance, themeModes.light),
  palette: themePaletteFactory(appInstance, themeModes.light),
});

export const darkTheme = createTheme({
  spacing: themeSpacingsFactory(appInstance),
  breakpoints: themeBreakpointsFactory(appInstance),
  typography: themeTypographyFactory(appInstance),
  components: themeComponentsFactory(appInstance, themeModes.dark),
  palette: themePaletteFactory(appInstance, themeModes.dark),
});

export const themes = {
  lightTheme: responsiveFontSizes(lightTheme),
  darkTheme: responsiveFontSizes(darkTheme),
};
