import React, { useLayoutEffect, useState } from 'react';
import { BrowserHistory } from 'history';

import { Router } from 'react-router-dom';
interface IProps {
  children: React.ReactNode;
  history: BrowserHistory;
}
export const CustomRouter = ({ children, history }: IProps): JSX.Element => {
  const [state, setState] = useState({
    action: history.action,
    location: history.location,
  });

  useLayoutEffect(() => history.listen(setState), [history]);

  return (
    <Router location={state.location} navigationType={state.action} navigator={history}>
      {children}
    </Router>
  );
};
