import { PaletteOptions } from '@mui/material';
import { defaultThemeLightColors, defaultThemeDarkColors } from './defaultThemeColors';
import { themeModes } from 'src/theming/themeModes';

export const defaultThemeLightPalette: PaletteOptions = {
  mode: themeModes.light,
  primary: {
    main: defaultThemeLightColors.primaryMainColor,
    light: defaultThemeLightColors.primaryLightColor,
    dark: defaultThemeLightColors.primaryDarkColor,
    contrastText: defaultThemeLightColors.primaryContrastText,
  },
  secondary: {
    main: defaultThemeLightColors.secondaryMainColor,
    light: defaultThemeLightColors.secondaryLightColor,
    dark: defaultThemeLightColors.secondaryDarkColor,
    contrastText: defaultThemeLightColors.secondaryContrastText,
  },
  info: {
    main: defaultThemeLightColors.infoMainColor,
    light: defaultThemeLightColors.infoLightColor,
    dark: defaultThemeLightColors.infoDarkColor,
    contrastText: defaultThemeLightColors.infoContrastText,
  },
  error: {
    main: defaultThemeLightColors.errorMainColor,
    light: defaultThemeLightColors.errorLightColor,
    dark: defaultThemeLightColors.errorDarkColor,
    contrastText: defaultThemeLightColors.errorContrastTextColor,
  },
};

export const defaultThemeDarkPalette: PaletteOptions = {
  mode: themeModes.dark,
  primary: {
    main: defaultThemeDarkColors.primaryMainColor,
    light: defaultThemeDarkColors.primaryLightColor,
    dark: defaultThemeDarkColors.primaryDarkColor,
    contrastText: defaultThemeDarkColors.primaryContrastText,
  },
  secondary: {
    main: defaultThemeDarkColors.secondaryMainColor,
    light: defaultThemeDarkColors.secondaryLightColor,
    dark: defaultThemeDarkColors.secondaryDarkColor,
    contrastText: defaultThemeDarkColors.secondaryContrastText,
  },
  info: {
    main: defaultThemeDarkColors.infoMainColor,
    light: defaultThemeDarkColors.infoLightColor,
    dark: defaultThemeDarkColors.infoDarkColor,
    contrastText: defaultThemeDarkColors.infoContrastText,
  },
  error: {
    main: defaultThemeDarkColors.errorMainColor,
    light: defaultThemeDarkColors.errorLightColor,
    dark: defaultThemeDarkColors.errorDarkColor,
    contrastText: defaultThemeDarkColors.errorContrastTextColor,
  },
};
