import { types } from 'mobx-state-tree';

import { FileResponse } from 'src/shared/types';

export const FileMstType = types.custom({
  name: 'NavItem',
  fromSnapshot(value: FileResponse) {
    return value;
  },
  toSnapshot(value: FileResponse) {
    return value;
  },
  isTargetType(value: FileResponse): boolean {
    return Boolean(value?.id && value?.fileName);
  },
  getValidationMessage(value: FileResponse): string {
    return `${value} doesn't look like a valid structure`;
  },
});
