import { types, Instance, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const DamageReportThirdParty = types.model('DamageReportThirdPartyModel', {
  [damageReportFieldNames.thirdPartyFirstName]: types.maybeNull(types.string),
  [damageReportFieldNames.thirdPartyLastName]: types.maybeNull(types.string),
  [damageReportFieldNames.thirdPartyLicenseNumber]: types.maybeNull(types.string),
  [damageReportFieldNames.thirdPartyStreet]: types.maybeNull(types.string),
  [damageReportFieldNames.thirdPartyCity]: types.maybeNull(types.string),
  [damageReportFieldNames.thirdPartyPostcode]: types.maybeNull(types.string),
  [damageReportFieldNames.thirdPartyPhoneNumber]: types.maybeNull(types.string),
});

export type DamageReportThirdPartyModel = Instance<typeof DamageReportThirdParty>;
export type IDamageReportThirdPartySnapshotOut = SnapshotOut<
  typeof DamageReportThirdParty
>;
export type IDamageReportThirdPartySnapshotIn = SnapshotIn<typeof DamageReportThirdParty>;
