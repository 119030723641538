import { types, Instance, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const UmUser = types.model('UmUserModel', {
  id: types.identifier,
  name: types.maybeNull(types.string),
  surname: types.maybeNull(types.string),
  active: types.maybeNull(types.boolean),
  activated: types.maybeNull(types.boolean),
  roles: types.maybeNull(types.array(types.string)),
});

export type UmUserModel = Instance<typeof UmUser>;
export type IUmUserSnapshotOut = SnapshotOut<typeof UmUser>;
export type IUmUserSnapshotIn = SnapshotIn<typeof UmUser>;
