import { DateTimeService } from 'src/lib/dateTimeService/dateTimeService';
import { DamageReportDto } from 'src/shared/types';
import { damageAssignments } from 'src/shared/constants/damageAssignments';
import { IDamageReportSnapshotOut } from '../damageReport';

export const damageReportRequestAdapter = (
  {
    isImmediateHelpRequired,
    isOtherPartyInvolved,
    thirdParties,
    reason,
    responsibility,
    damageDay,
    damageTime,
    licenseNumber,
    damageCause,
    driverFirstName,
    driverLastName,
    driverCity,
    driverPhoneNumber,
    driverPostcode,
    driverStreet,
    detailedDamageDescription,
    breakdownStreet,
    breakdownPostcode,
    breakdownCountry,
    breakdownCity,
    recorderByThePolice,
    receivingPoliceStation,
    policeFileNumber,
    alcoholOrDrugUse,
    warnedByThePolice,
    whoWasWarnedByThePolice,
    isWitnesses,
    witnessName,
    witnessCity,
    witnessPhoneNumber,
    witnessPostcode,
    witnessStreet,
    withnessEmail,
    isDriverLicenseAvailable,
    driverLicenseClass,
    dateOfIssue,
    attachments,
    assignments,
    damageType,
  }: IDamageReportSnapshotOut,
  dateTimeService: DateTimeService
): DamageReportDto => {
  const formattedData: DamageReportDto = {};
  if (isImmediateHelpRequired && reason) {
    formattedData.reason = reason;
    return formattedData;
  }
  if (damageType) {
    formattedData.kindOfDamage = damageType;
  }

  formattedData.liability = assignments?.some(
    (item) => item === damageAssignments.liability
  );
  formattedData.fullyCoverage = assignments?.some(
    (item) => item === damageAssignments.fullyComprehensive
  );
  formattedData.partialCoverage = assignments?.some(
    (item) => item === damageAssignments.partiallyComprehensive
  );
  formattedData.protectionLetter = assignments?.some(
    (item) => item === damageAssignments.letterOfProtection
  );
  formattedData.dayOfDamage = damageDay
    ? dateTimeService.getFullDateISOString(damageDay)
    : null;
  formattedData.timeOfDamage = damageTime
    ? dateTimeService.getDateISOStringWithTime(damageTime)
    : null;
  formattedData.kfzLicenseNumber = licenseNumber;
  formattedData.rootCause = damageCause;

  formattedData.driverInformation = {
    firstname: driverFirstName,
    surname: driverLastName,
    city: driverCity,
    phoneNumber: driverPhoneNumber,
    postCode: driverPostcode,
    street: driverStreet,
  };
  formattedData.detailedDamageDescription = detailedDamageDescription;

  formattedData.accidentScene = {
    street: breakdownStreet,
    city: breakdownCity,
    postCode: breakdownPostcode,
    country: breakdownCountry,
  };

  formattedData.incidentRecordedByPolice = recorderByThePolice;

  formattedData.areThereWitnesses = isWitnesses;

  if (isWitnesses) {
    formattedData.witnessesInformation = {
      nameAndSurname: witnessName,
      email: withnessEmail,
      phoneNumber: witnessPhoneNumber,
      city: witnessCity,
      street: witnessStreet,
      postCode: witnessPostcode,
    };
  }

  if (recorderByThePolice) {
    formattedData.policeRecord = {
      receivingPoliceStation,
      policeFileNumber,
      someoneWarnedByPolice: warnedByThePolice,
      diagnosedAlcoholOrDrugUse: alcoholOrDrugUse,
      whoWarnedByPolice: whoWasWarnedByThePolice,
    };
  }

  if (isOtherPartyInvolved) {
    formattedData.responsibility = responsibility;
    formattedData.otherPartyInformation = thirdParties.map((item) => ({
      firstname: item.thirdPartyFirstName,
      surname: item.thirdPartyLastName,
      street: item.thirdPartyStreet,
      postCode: item.thirdPartyPostcode,
      city: item.thirdPartyCity,
      phoneNumber: item.thirdPartyPhoneNumber,
      licenseNumber: item.thirdPartyLicenseNumber,
    }));
  }

  formattedData.drivingLicenseAvailable = isDriverLicenseAvailable;

  if (isDriverLicenseAvailable) {
    formattedData.drivingLicenseInformation = {
      drivingLicenseClass: driverLicenseClass,
      dateOfIssue: dateOfIssue
        ? dateTimeService.getDateISOStringWithoutTimeOffset(dateOfIssue)
        : null,
    };
  }

  formattedData.attachments = attachments.map((item) => item.file);

  return formattedData;
};
