import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
  getRoot,
} from 'mobx-state-tree';

import { DamageDto } from 'src/shared/types';
import { StoreModel, GlobalFilterModel } from '..';
import { getGlobalFilterModel } from 'src/mst/selectors';

import { Damage } from './damage';
import { IDamageSnapshotOut } from './damage';

export const Damages = types
  .model('DamagesModel', {
    _list: types.maybeNull(types.array(Damage)),
    searchValue: types.string,
    status: types.string,
  })
  .actions((self) => {
    let initialState: IDamagesSnapshotOut;

    const {
      env: { httpClient },
    } = getEnv(self);

    const root: StoreModel = getRoot(self);
    const globalFilter: GlobalFilterModel = getGlobalFilterModel(root);

    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetDamages(): void {
        applySnapshot(self, initialState);
      },
      setSearchValue(value: string): void {
        self.searchValue = value;
      },
      setStatus(value: string) {
        self.status = value;
      },
      fetchDamages: flow(function* () {
        const { searchValue, status } = self;
        const { customerValue } = globalFilter;

        if (!customerValue?.[0]) {
          applySnapshot(self, {
            ...self,
            _list: [],
          });
          return;
        }

        const data: { result: DamageDto[]; count: number } = yield httpClient.post(
          'get-damages',
          {
            statuses: [status],
            searchTerm: searchValue,
            allowedCustomers: customerValue[0] ? customerValue : [],
            skip: 0,
            take: 9999999,
          }
        );

        applySnapshot(self, {
          ...self,
          _list: data?.result || [],
        });
      }),
    };
  })
  .views((self) => {
    const {
      env: { dateTimeService },
    } = getEnv(self);
    return {
      get list(): IDamageSnapshotOut[] {
        return Array.isArray(self._list)
          ? self._list.map((item) => {
              return {
                ...item,
                dayOfDamage: dateTimeService.getDateISOStringWithoutTimeOffset(
                  item.dayOfDamage
                ),
              };
            })
          : [];
      },
    };
  });

export type DamagesModel = Instance<typeof Damages>;
export type IDamagesSnapshotOut = SnapshotOut<typeof Damages>;
export type IDamagesSnapshotIn = SnapshotIn<typeof Damages>;
