import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';

import { FileMstType } from 'src/mst/types';
import { CustomerDto, FileDto } from 'src/shared/types';

export const Customer = types
  .model('CustomerModel', {
    name: types.maybeNull(types.string),
    amsIdNr: types.maybeNull(types.string),
    street: types.maybeNull(types.string),
    country: types.maybeNull(types.string),
    postcode: types.maybeNull(types.string),
    city: types.maybeNull(types.string),
    email: types.maybeNull(types.string),
    accountOwner: types.maybeNull(types.string),
    institute: types.maybeNull(types.string),
    accountNo: types.maybeNull(types.string),
    blz: types.maybeNull(types.string),
    iban: types.maybeNull(types.string),
    bic: types.maybeNull(types.string),
    files: types.maybeNull(types.array(FileMstType)),
  })
  .actions((self) => {
    let initialState: ICustomerSnapshotOut;
    const {
      env: { httpClient },
    } = getEnv(self);
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetCustomer(): void {
        applySnapshot(self, initialState);
      },
      fetchCustomerDocuments: flow(function* ({ queryKey }: { queryKey: string[] }) {
        const [, id] = queryKey;
        if (!id) {
          return;
        }
        const data: FileDto[] = yield httpClient.get(`customers/${id}/documents`);

        applySnapshot(self, {
          ...self,
          files:
            data?.map((item) => ({
              id: item.amsIdNr,
              fileName: `${item.title}.${item.type}`,
            })) || [],
        });
      }),
      fetchCustomer: flow(function* ({ queryKey }: { queryKey: string[] }) {
        const [, id] = queryKey;
        if (!id) {
          return;
        }
        const data: CustomerDto = yield httpClient.get(`customers/${id}`);

        applySnapshot(self, {
          amsIdNr: data.amsIdNr,
          street: data.street,
          country: data.country,
          postcode: data.postcode,
          city: data.city,
          email: data.email,
          accountOwner: data.accountOwner,
          institute: data.bank,
          accountNo: data.account,
          blz: data.blz,
          iban: data.iban,
          bic: data.bic,
          name: data.name,
        });
      }),
    };
  });

export type CustomerModel = Instance<typeof Customer>;
export type ICustomerSnapshotOut = SnapshotOut<typeof Customer>;
export type ICustomerSnapshotIn = SnapshotIn<typeof Customer>;
