import { AnyAbility, AbilityBuilder, Ability } from '@casl/ability';
import { customerRoles, internalRole } from 'src/shared/constants';
import { AbilityActions, AbilitySubjects } from './constants';
import { isSantanderInstance } from 'src/environment';

export const updateAbilityInstance = (ability: AnyAbility, roles: string[]): void => {
  if (!Array.isArray(roles) || !roles.length) {
    return;
  }

  const { can, rules } = new AbilityBuilder(Ability);

  if (roles.some((r) => r === customerRoles.customer)) {
    can(AbilityActions.see, [
      AbilitySubjects.home,
      AbilitySubjects.dashboardPage,
      AbilitySubjects.contractsListPage,
      AbilitySubjects.damagesListPage,
      AbilitySubjects.damageDetailsPage,
      AbilitySubjects.contractDetailsPage,
      AbilitySubjects.customersPage,
      AbilitySubjects.customerDetailsPage,
      AbilitySubjects.faq,
    ]);
  }

  if (roles.some((r) => r === customerRoles.customer) && !isSantanderInstance) {
    can(AbilityActions.see, [AbilitySubjects.damageReportPage]);
  }

  if (roles.some((r) => r === internalRole.admin || r === internalRole.newsManager)) {
    can(AbilityActions.see, [
      AbilitySubjects.userManagementPage,
      AbilitySubjects.umNewsSection,
      AbilitySubjects.faq,
    ]);
  }

  if (roles.some((r) => r === internalRole.admin)) {
    can(AbilityActions.see, [
      AbilitySubjects.umUsersSection,
      AbilitySubjects.umNotificationsSection,
    ]);
    can(
      [AbilityActions.create, AbilityActions.edit, AbilityActions.delete],
      AbilitySubjects.faq
    );
  }

  ability.update(rules);
};
