import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
} from 'mobx-state-tree';

export const Damage = types
  .model('DamageModel', {
    amsIdNr: types.string,
    risk: types.maybeNull(types.string),
    dayOfDamage: types.maybeNull(types.string),
    assignment: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IDamageSnapshotOut;
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetDamage(): void {
        applySnapshot(self, initialState);
      },
    };
  });

export type DamageModel = Instance<typeof Damage>;
export type IDamageSnapshotOut = SnapshotOut<typeof Damage>;
export type IDamageSnapshotIn = SnapshotIn<typeof Damage>;
