import { useQuery } from '@tanstack/react-query';

import { useHttpClient } from 'src/lib/http-client/use-http-client';
import { reactQueryKeys } from '../constants';

export interface DocumentFormatsDto {
  availableFormats: string[];
}

export const useDocumentFormats = (): string[] => {
  const httpClient = useHttpClient();

  const { data } = useQuery(
    [reactQueryKeys.availableDocumentFormats],
    async () => {
      const response: DocumentFormatsDto = await httpClient.get(
        'documents/available-formats'
      );
      return response.availableFormats.map((item) => `.${item}`);
    },
    {
      staleTime: 300000,
    }
  );

  return data;
};
