import { types, Instance, SnapshotIn, SnapshotOut } from 'mobx-state-tree';

export const DamageReportAttachment = types.model('DamageReportAttachmentModel', {
  id: types.maybeNull(types.string),
  file: types.maybeNull(types.frozen()),
});

export type DamageReportAttachmentModel = Instance<typeof DamageReportAttachment>;
export type IDamageReportAttachmentSnapshotOut = SnapshotOut<
  typeof DamageReportAttachment
>;
export type IDamageReportAttachmentSnapshotIn = SnapshotIn<typeof DamageReportAttachment>;
