export enum damageReportCauses {
  animalCrossing = 'animalCrossing',
  hail = 'hail',
  lightning = 'lightning',
  storm = 'storm',
  flooding = 'flooding',
  fire = 'fire',
  animalBite = 'animalBite',
  burglaryOrTheft = 'burglaryOrTheft',
  scorchingDamageOrShortCircuit = 'scorchingDamageOrShortCircuit',
  stoneChipsOrBrokenGlass = 'stoneChipsOrBrokenGlass',
  carelessness = 'carelessness',
  externalOrUnknown = 'externalOrUnknown',
  lossOfControl = 'lossOfControl',
  vandalism = 'vandalism',
  smoothness = 'smoothness',
  objectOnTheToad = 'objectOnTheToad',
  loadSecuringOrLoss = 'loadSecuringOrLoss',
  wearAndTear = 'wearAndTear',
  laneChange = 'laneChange',
  rightOfWayInfringement = 'rightOfWayInfringement',
  breakdown = 'breakdown',
}
