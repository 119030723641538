import { createContext, useContext } from 'react';

export interface ConnectivityService {
  readonly isConnected: boolean;
  readonly wasUserNotifiedAboutConnectionError: boolean;
  readonly reportConnectionError: () => void;
  readonly reportConnectionSuccess: () => void;
  readonly reportUserWasNotifiedAboutConnectionError: () => void;
}

export const ConnectivityServiceContext = createContext<ConnectivityService | null>(null);

export function useConnectivityService(): ConnectivityService {
  const service = useContext(ConnectivityServiceContext);
  if (service == null) {
    throw new Error(
      'ConnectivityServiceContext.Provider is not set in the react component tree'
    );
  }
  return service;
}
