import {
  types,
  applySnapshot,
  getSnapshot,
  Instance,
  SnapshotOut,
  SnapshotIn,
  flow,
  getEnv,
} from 'mobx-state-tree';
import qs from 'query-string';

import { ListMenuItem } from 'src/components';
import { UmUserFormType } from 'src/pages/UserManagementPage/sections/UmUsersSection/components/UmUserForm/UmUserFormType';

import { UmUser, IUmUserSnapshotIn } from './umUser';
import { mutateAdapter } from './helpers';

export const UmUsers = types
  .model('UmUsersModel', {
    users: types.maybeNull(types.array(UmUser)),
    selectedUser: types.maybeNull(types.reference(UmUser)),
    page: types.number,
    rowsPerPage: types.number,
    total: types.number,
    searchTerm: types.maybeNull(types.string),
  })
  .actions((self) => {
    let initialState: IUmUserSnapshotIn;
    const {
      env: { httpClient },
    } = getEnv(self);
    return {
      afterCreate(): void {
        initialState = getSnapshot(self);
      },
      resetGlobalFilter(): void {
        applySnapshot(self, initialState);
      },
      selectUser(userId: string): void {
        applySnapshot(self, { ...self, selectedUser: userId });
      },
      setPage(value: number) {
        self.page = value;
      },
      setRowsPerPage(value: number) {
        self.rowsPerPage = value;
      },
      setSearchTerm(value: string) {
        applySnapshot(self, { ...self, page: 0, searchTerm: value, selectedUser: null });
      },
      fetchUsers: flow(function* fetchUsers(selectUserId?: string) {
        const { page, rowsPerPage, searchTerm } = self;
        const skip = (page + 1) * rowsPerPage - rowsPerPage;
        const take = rowsPerPage;
        const queryParams: { skip: number; take: number; searchTerm?: string } = {
          skip,
          take,
        };
        if (searchTerm) {
          queryParams.searchTerm = searchTerm;
        }
        const data = yield httpClient.get(`users?${qs.stringify(queryParams)}`);
        selectUserId ??= self.selectedUser?.id;
        if (selectUserId && data && data.result) {
          let found = false;
          for (let i = 0; i < data.result.length; i++) {
            if (data.result[i] && data.result[i].id == selectUserId) {
              found = true;
              break;
            }
          }
          if (!found) {
            selectUserId = data?.result?.[0]?.id || null;
          }
        }
        applySnapshot(self, {
          ...self,
          users: data?.result,
          selectedUser: selectUserId,
          total: data.count,
        });
      }),
      /*eslint-disable */
      createUser: flow(function* (values: UmUserFormType) {
        const body = mutateAdapter(values);
        return httpClient.post(`users/internal`, body) as Promise<{ id: string }>;
      }),
      /*eslint-enable */
      updateUser: flow(function* (values: UmUserFormType) {
        const body = mutateAdapter(values);
        yield httpClient.put(`users/${values.id}/internal`, body);
      }),
    };
  })
  .views((self) => {
    return {
      get usersList(): ListMenuItem[] {
        return Array.isArray(self.users)
          ? self.users.map((user) => {
              return {
                id: user.id,
                text: `${user.name} ${user.surname}`,
                style: {
                  opacity: user.active ? 1 : 0.5,
                },
              };
            })
          : [];
      },
    };
  });

export type UmUsersModel = Instance<typeof UmUsers>;
export type IUmUsersSnapshotOut = SnapshotOut<typeof UmUsers>;
export type IUmUsersSnapshotIn = SnapshotIn<typeof UmUsers>;
