import { IStoreSnapshotOut } from './models';

import {
  damageReportProcessSteps,
  damageReportFieldNames,
} from 'src/pages/DamageReportPage/constants';
import { damageStatuses } from 'src/shared/constants';

export const defaultStoreState: IStoreSnapshotOut = {
  authentication: {
    isAuth: null,
    isTokensLoading: false,
  },
  user: {
    id: null,
    name: null,
    surname: null,
    email: null,
    roles: [],
    divisions: [],
  },
  globalFilter: {
    customers: null,
    customer: null,
  },
  contracts: {
    _list: [],
    searchValue: '',
  },
  damages: {
    _list: [],
    status: damageStatuses.open,
    searchValue: '',
  },
  umUsers: {
    users: [],
    selectedUser: null,
    page: 0,
    rowsPerPage: 10,
    total: 0,
    searchTerm: '',
  },
  contractDetails: {
    amsIdNr: null,
    contractNumber: null,
    insuranceCompany: null,
    division: null,
    annualPremiumInclusiveTaxes: null,
    product: null,
    status: null,
    startDate: null,
    endDate: null,
    paymentMethod: null,
    premiumInclusiveTaxes: null,
    customer: null,
    street: null,
    postcode: null,
    city: null,
    risk: null,
    files: [],
  },
  damageDetails: {
    id: null,
    risk: null,
    contractNumber: null,
    dayOfDamage: null,
    classification: null,
    status: null,
    totalDamage: null,
    excess: null,
    dayOfReport: null,
    cost: null,
    product: null,
    customer: null,
    files: [],
  },
  damageReport: {
    currentStep: damageReportProcessSteps.isImmediateHelpRequired,
    previousSteps: [],
    isValid: null,
    [damageReportFieldNames.isImmediateHelpRequired]: null,
    [damageReportFieldNames.reason]: null,
    [damageReportFieldNames.isAccident]: null,
    [damageReportFieldNames.isOwnVehicleDamaged]: null,
    [damageReportFieldNames.damageType]: null,
    [damageReportFieldNames.assignments]: null,
    [damageReportFieldNames.isOtherPartyInvolved]: null,
    [damageReportFieldNames.responsibility]: null,
    thirdParties: [],
    [damageReportFieldNames.addMoreAccidentOpponents]: null,
    [damageReportFieldNames.damageDay]: null,
    [damageReportFieldNames.damageTime]: null,
    [damageReportFieldNames.licenseNumber]: null,
    [damageReportFieldNames.damageCause]: null,
    [damageReportFieldNames.driverFirstName]: null,
    [damageReportFieldNames.driverLastName]: null,
    [damageReportFieldNames.driverStreet]: null,
    [damageReportFieldNames.driverPostcode]: null,
    [damageReportFieldNames.driverCity]: null,
    [damageReportFieldNames.driverPhoneNumber]: null,
    [damageReportFieldNames.detailedDamageDescription]: null,
    [damageReportFieldNames.breakdownStreet]: null,
    [damageReportFieldNames.breakdownPostcode]: null,
    [damageReportFieldNames.breakdownCity]: null,
    [damageReportFieldNames.breakdownCountry]: null,
    [damageReportFieldNames.recorderByThePolice]: false,
    [damageReportFieldNames.receivingPoliceStation]: null,
    [damageReportFieldNames.policeFileNumber]: null,
    [damageReportFieldNames.alcoholOrDrugUse]: false,
    [damageReportFieldNames.warnedByThePolice]: false,
    [damageReportFieldNames.whoWasWarnedByThePolice]: null,
    [damageReportFieldNames.isDriverLicenseAvailable]: false,
    [damageReportFieldNames.driverLicenseClass]: null,
    [damageReportFieldNames.dateOfIssue]: null,
    [damageReportFieldNames.isWitnesses]: false,
    [damageReportFieldNames.witnessName]: null,
    [damageReportFieldNames.witnessStreet]: null,
    [damageReportFieldNames.witnessPostcode]: null,
    [damageReportFieldNames.witnessCity]: null,
    [damageReportFieldNames.witnessPhoneNumber]: null,
    [damageReportFieldNames.withnessEmail]: null,
    [damageReportFieldNames.attachments]: [],
  },
  customer: {
    name: null,
    amsIdNr: null,
    street: null,
    city: null,
    postcode: null,
    country: null,
    email: null,
    accountOwner: null,
    institute: null,
    accountNo: null,
    blz: null,
    iban: null,
    bic: null,
    files: [],
  },
};
