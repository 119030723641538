import { Components } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
import { defaultThemeDarkColors, defaultThemeLightColors } from './defaultThemeColors';

export const defaultLightThemeComponents: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        fontSize: '10px',
      },
      body: {
        height: '100%',
        margin: '0px auto',
      },
      a: {
        color: defaultThemeLightColors.secondaryDarkColor,
        textDecoration: 'none',
        fontWeight: 400,
        fontSize: '1.6rem',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
      'div,ul,tbody': {
        scrollbarWidth: 'thin',
        scrollbarColor: `#888888 #595959`,
        '&::-webkit-scrollbar': {
          width: 12,
          height: 6,
        },
        '&::-webkit-scrollbar-track': {
          background: defaultThemeLightColors.primaryDarkColor,
        },
        '&::-webkit-scrollbar-thumb': {
          background: defaultThemeLightColors.secondaryMainColor,
        },
      },
      '& .MuiCalendarPicker-root': {
        '& .MuiPickersDay-root': {
          fontSize: '1.6rem',
        },
        '& .MuiTypography-root': {
          fontSize: '1.6rem',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        fontSize: '1.6rem',
        fontWeight: 600,
        textTransform: 'none',
      },
      containedSecondary: {
        backgroundColor: defaultThemeLightColors.secondaryDarkColor,
        '&:hover': {
          backgroundColor: defaultThemeLightColors.secondaryMainColor,
        },
      },
      outlinedSecondary: {
        borderColor: defaultThemeLightColors.secondaryDarkColor,
        color: defaultThemeLightColors.secondaryDarkColor,
        '&:hover': {
          borderColor: defaultThemeLightColors.secondaryMainColor,
          color: defaultThemeLightColors.secondaryMainColor,
        },
      },
      outlinedPrimary: {
        borderColor: defaultThemeLightColors.primaryContrastText,
        color: defaultThemeLightColors.primaryContrastText,
        '&:hover': {
          borderColor: defaultThemeLightColors.secondaryMainColor,
          color: defaultThemeLightColors.secondaryMainColor,
        },
      },
      outlined: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: 400,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: defaultThemeLightColors.errorMainColor,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
        '&:not(.Mui-checked)': {
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        '&.Mui-selected': {
          backgroundColor: `${defaultThemeLightColors.secondaryDarkColor}!important`,
          color: defaultThemeLightColors.secondaryContrastText,
        },
      },
      today: {
        backgroundColor: `${defaultThemeLightColors.secondaryLightColor}!important`,
        color: defaultThemeLightColors.primaryContrastText,
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeLightColors.primaryMainColor,
      },
    },
  },
  MuiClockPicker: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeLightColors.primaryMainColor,
        '&>div:nth-of-type(2)>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
            color: defaultThemeLightColors.secondaryContrastText,
          },
        },
        '&>div>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: defaultThemeLightColors.secondaryDarkColor,
            color: defaultThemeLightColors.secondaryContrastText,
          },
        },
      },
    },
  },
};

export const defaultDarkThemeComponents: Components = {
  MuiCssBaseline: {
    styleOverrides: {
      html: {
        height: '100%',
        fontSize: '10px',
      },
      body: {
        height: '100%',
        margin: '0px auto',
      },
      a: {
        color: defaultThemeDarkColors.secondaryContrastText,
        textDecoration: 'none',
        fontWeight: 400,
        fontSize: '1.6rem',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
      '#root': {
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100%',
      },
      'div,ul,tbody': {
        scrollbarWidth: 'thin',
        scrollbarColor: `#888888 #595959`,
        '&::-webkit-scrollbar': {
          width: 12,
          height: 6,
        },
        '&::-webkit-scrollbar-track': {
          background: defaultThemeDarkColors.primaryDarkColor,
        },
        '&::-webkit-scrollbar-thumb': {
          background: defaultThemeDarkColors.secondaryMainColor,
        },
      },
      '& .MuiCalendarPicker-root': {
        '& .MuiPickersDay-root': {
          fontSize: '1.6rem',
        },
        '& .MuiTypography-root': {
          fontSize: '1.6rem',
        },
      },
    },
  },
  MuiButton: {
    defaultProps: {
      disableElevation: true,
      disableRipple: true,
    },
    styleOverrides: {
      root: {
        borderRadius: 0,
        fontSize: '1.6rem',
        fontWeight: 600,
        textTransform: 'none',
      },
      outlinedPrimary: {
        fontWeight: 400,
        color: defaultThemeDarkColors.primaryContrastText,
        borderColor: defaultThemeDarkColors.primaryContrastText,
        transition: '0.2s',
        '&:hover': {
          opacity: 0.8,
          backgroundColor: 'rgba(255, 255, 255, 0.2)',
          borderColor: defaultThemeDarkColors.primaryContrastText,
        },
      },
      outlinedSecondary: {
        borderColor: defaultThemeDarkColors.secondaryMainColor,
        color: defaultThemeDarkColors.secondaryContrastText,
        '&:hover': {
          borderColor: defaultThemeDarkColors.secondaryDarkColor,
        },
      },
      outlined: {
        borderWidth: 2,
        '&:hover': {
          borderWidth: 2,
        },
      },
    },
  },
  MuiLink: {
    styleOverrides: {
      root: {
        cursor: 'pointer',
        fontWeight: 400,
      },
    },
  },
  MuiFormLabel: {
    styleOverrides: {
      asterisk: {
        color: defaultThemeDarkColors.errorMainColor,
      },
    },
  },
  MuiCheckbox: {
    styleOverrides: {
      root: {
        '& .MuiSvgIcon-root': {
          width: 20,
          height: 20,
        },
        '&:not(.Mui-checked)': {
          '& .MuiSvgIcon-root': {
            width: 20,
            height: 20,
          },
        },
      },
    },
  },
  MuiPickersDay: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeDarkColors.primaryDarkColor,
        '&.Mui-selected': {
          backgroundColor: `${defaultThemeDarkColors.secondaryDarkColor}!important`,
          color: defaultThemeLightColors.secondaryContrastText,
        },
      },
      today: {
        backgroundColor: `${defaultThemeDarkColors.secondaryMainColor}!important`,
        color: defaultThemeDarkColors.primaryContrastText,
      },
    },
  },
  MuiCalendarPicker: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeDarkColors.primaryMainColor,
      },
    },
  },
  MuiClockPicker: {
    styleOverrides: {
      root: {
        backgroundColor: defaultThemeDarkColors.primaryMainColor,
        '&>div:nth-of-type(2)>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: defaultThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: defaultThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: defaultThemeDarkColors.secondaryDarkColor,
            color: defaultThemeDarkColors.secondaryContrastText,
          },
        },
        '&>div>div': {
          '&>div:nth-of-type(2)': {
            backgroundColor: defaultThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(3)': {
            backgroundColor: defaultThemeDarkColors.secondaryDarkColor,
          },
          '&>div:nth-of-type(4) span.Mui-selected': {
            backgroundColor: defaultThemeDarkColors.secondaryDarkColor,
            color: defaultThemeDarkColors.secondaryContrastText,
          },
        },
      },
    },
  },
};
