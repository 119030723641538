import { PaletteOptions } from '@mui/material';
import {
  santanderThemeLightColors,
  santanderThemeDarkColors,
} from './santanderThemeColors';
import { themeModes } from 'src/theming/themeModes';

export const santanderThemeLightPalette: PaletteOptions = {
  mode: themeModes.light,
  primary: {
    main: santanderThemeLightColors.primaryMainColor,
    light: santanderThemeLightColors.primaryLightColor,
    dark: santanderThemeLightColors.primaryDarkColor,
    contrastText: santanderThemeLightColors.primaryContrastText,
  },
  secondary: {
    main: santanderThemeLightColors.secondaryMainColor,
    light: santanderThemeLightColors.secondaryLightColor,
    dark: santanderThemeLightColors.secondaryDarkColor,
    contrastText: santanderThemeLightColors.secondaryContrastText,
  },
  info: {
    main: santanderThemeLightColors.infoMainColor,
    light: santanderThemeLightColors.infoLightColor,
    dark: santanderThemeLightColors.infoDarkColor,
    contrastText: santanderThemeLightColors.infoContrastText,
  },
  error: {
    main: santanderThemeLightColors.errorMainColor,
    light: santanderThemeLightColors.errorLightColor,
    dark: santanderThemeLightColors.errorDarkColor,
    contrastText: santanderThemeLightColors.errorContrastTextColor,
  },
};

export const santanderThemeDarkPalette: PaletteOptions = {
  mode: themeModes.dark,
  primary: {
    main: santanderThemeDarkColors.primaryMainColor,
    light: santanderThemeDarkColors.primaryLightColor,
    dark: santanderThemeDarkColors.primaryDarkColor,
    contrastText: santanderThemeDarkColors.primaryContrastText,
  },
  secondary: {
    main: santanderThemeDarkColors.secondaryMainColor,
    light: santanderThemeDarkColors.secondaryLightColor,
    dark: santanderThemeDarkColors.secondaryDarkColor,
    contrastText: santanderThemeDarkColors.secondaryContrastText,
  },
  info: {
    main: santanderThemeDarkColors.infoMainColor,
    light: santanderThemeDarkColors.infoLightColor,
    dark: santanderThemeDarkColors.infoDarkColor,
    contrastText: santanderThemeDarkColors.infoContrastText,
  },
  error: {
    main: santanderThemeDarkColors.errorMainColor,
    light: santanderThemeDarkColors.errorLightColor,
    dark: santanderThemeDarkColors.errorDarkColor,
    contrastText: santanderThemeDarkColors.errorContrastTextColor,
  },
};
