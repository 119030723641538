import { types, Instance, SnapshotOut, SnapshotIn } from 'mobx-state-tree';

export const CustomerGlobalFilterItem = types.model('CustomerGlobalFilterItemModel', {
  title: types.string,
  value: types.string,
  additionalTitles: types.maybeNull(types.array(types.string)),
});

export type CustomerGlobalFilterItemModel = Instance<typeof CustomerGlobalFilterItem>;
export type ICustomerGlobalFilterItemSnapshotOut = SnapshotOut<
  typeof CustomerGlobalFilterItem
>;
export type ICustomerGlobalFilterItemSnapshotIn = SnapshotIn<
  typeof CustomerGlobalFilterItem
>;
