import { object, string, boolean, InferType } from 'yup';

import { damageReportFieldNames } from 'src/pages/DamageReportPage/constants';

export const immediateHelpSchema = object({
  [damageReportFieldNames.isImmediateHelpRequired]: boolean().required().nullable(),
  [damageReportFieldNames.reason]: string().when(
    damageReportFieldNames.isImmediateHelpRequired,
    {
      is: (val: boolean) => val,
      then: string().required().nullable(),
      otherwise: string().nullable(),
    }
  ),
});

export type ImmediateHelpSchemaType = InferType<typeof immediateHelpSchema>;
