import React, { ReactElement, Suspense } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { SnackbarProvider } from 'notistack';
import { I18nextProvider } from 'react-i18next';

import { StoreProvider } from 'src/mst/StoreProvider';
import { withTheme } from 'src/theming';
import { CustomRouter } from 'src/routing/CustomRouter';
import { history } from 'src/routing/history';
import { queryClient } from 'src/reactQuery';
import { AbilityContextProvider } from 'src/roleAccesses';
import { ConnectivityIndicatorInfrastructure } from 'src/lib/connectivity-indicator/connectivity-indicator-infrastructure';
import { HttpInfrastructure } from 'src/lib/http-client/http-infrastructure';
import App from './App';
import i18n from 'src/i18n/i18-next.config';

export const AppWithProviders = withTheme((): ReactElement => {
  return (
    <QueryClientProvider client={queryClient}>
      <CustomRouter history={history}>
        <ConnectivityIndicatorInfrastructure>
          <HttpInfrastructure>
            <I18nextProvider i18n={i18n}>
              <StoreProvider>
                <AbilityContextProvider>
                  <Suspense fallback='Loading...'>
                    <SnackbarProvider
                      preventDuplicate
                      style={{ maxWidth: 500 }}
                      anchorOrigin={{
                        horizontal: 'right',
                        vertical: 'bottom',
                      }}
                    >
                      <App />
                    </SnackbarProvider>
                  </Suspense>
                </AbilityContextProvider>
              </StoreProvider>
            </I18nextProvider>
          </HttpInfrastructure>
        </ConnectivityIndicatorInfrastructure>
      </CustomRouter>
    </QueryClientProvider>
  );
});
