import { types, Instance, flow, getRoot, getEnv } from 'mobx-state-tree';
import { StoreModel } from '../root';
import {
  AuthResponse,
  ResponseError,
  ResetPasswordDto,
  ChangePasswordDto,
} from 'src/shared/types';

import { applicationVersion } from 'src/environment';

export type AuthenticationModel = Instance<typeof Authentication>;

export const Authentication = types
  .model('AuthenticationModel', {
    isAuth: types.maybeNull(types.boolean),
    isTokensLoading: types.boolean,
  })
  .actions((self) => {
    return {
      setAuth(value: boolean): void {
        self.isAuth = value;
      },
      setTokensLoading(value: boolean) {
        self.isTokensLoading = value;
      },
    };
  })
  .actions((self) => {
    const {
      env: { httpClient, storageService },
    } = getEnv(self);
    const { user }: StoreModel = getRoot(self);
    return {
      signIn: flow(function* ({ email, password }: { email: string; password: string }) {
        const data: AuthResponse = yield httpClient.post('users/login', {
          email,
          password,
        });
        storageService.setTokens(data.accessToken, data.refreshToken);
        self.setAuth(true);
      }),
      logout: flow(function* () {
        try {
          yield httpClient.post('users/logout');
        } catch (error) {
          Promise.reject(error);
        } finally {
          self.setAuth(false);
          user.clearUserData();
          storageService.clear();
        }
      }),
      checkAuth: flow(function* () {
        const refreshToken = storageService.getItem('refreshToken');
        if (refreshToken && !self.isTokensLoading) {
          try {
            self.setTokensLoading(true);
            const data: AuthResponse = yield httpClient.post(
              `/api/users/refresh-token`,
              { refreshToken },
              {
                axiosConfig: {
                  headers: { AppVersion: applicationVersion },
                },
              }
            );
            storageService.setTokens(data.accessToken, data.refreshToken);
            self.setAuth(true);
          } catch (response) {
            const refreshTokenErrors: string[] = (response as ResponseError)?.error
              ?.response?.data?.errors?.refreshToken;
            if (
              !refreshTokenErrors ||
              (Array.isArray(refreshTokenErrors) &&
                refreshTokenErrors?.some(
                  (item) =>
                    item === "Refresh token doesn't match the saved token" ||
                    item === 'Refresh token has expired, user needs to relogin' ||
                    item === 'Refresh token has been revoked.' ||
                    item === "Refresh token doesn't exist."
                ))
            ) {
              storageService.removeTokens();
              self.setAuth(false);
              user.clearUserData();
            }
          } finally {
            self.setTokensLoading(false);
          }
        } else if (!self.isTokensLoading) {
          self.setAuth(false);
          user.clearUserData();
        }
      }),
      restorePassword: (email: string): Promise<boolean> => {
        if (email) {
          return httpClient.post('users/reset-password', {
            email,
          });
        }
        return null;
      },
      resetPassword: flow(function* (body: ResetPasswordDto) {
        yield httpClient.put('users/confirm-reset-password', {
          ...body,
        });
      }),
      changePassword: flow(function* (body: ChangePasswordDto) {
        yield httpClient.put('users/change-password', body);
      }),
    };
  });
