import { BreakpointsOptions } from '@mui/material';

export const santanderThemeBreakpoints: BreakpointsOptions = {
  values: {
    xs: 0,
    sm: 500,
    md: 800,
    lg: 1200,
    xl: 1640,
  },
};
