import { UmUserFormType } from 'src/pages/UserManagementPage/sections/UmUsersSection/components/UmUserForm/UmUserFormType';

interface UserMutationDto {
  name: string;
  surname: string;
  email: string;
  phoneNumber: string;
  active: boolean;
  roles: { name: string }[];
}

export const mutateAdapter = (data: UmUserFormType): UserMutationDto => {
  return {
    name: data.name,
    surname: data.surname,
    email: data.email,
    phoneNumber: data.phoneNumber,
    active: data.active,
    roles: data.roles,
  };
};
